import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import CircleCheck from '../icons/CircleCheck';
import CircleX from '../icons/CircleX';
import MessageBox from '../MessageBox';
import Timeline from './Timeline';
import { smallBreakpoint } from '../breakpoints';
import { getInfo } from '@web/utils/getVoterInfo';
import { getNoOnlineRegistrationAvailableDeadlineDate } from './Timeline/utils';
import theme from '@web/styles/theme';

const DATE_FORMAT = 'MM-DD-YY';
dayjs.extend(customParseFormat);

const { Panel } = Collapse;

const CollapseAntdStyles = createGlobalStyle`
    &&& {
    .ant-collapse-content {
      background: ${({ theme }) => theme.colors.transparent}!important;
      border-top: 0px !important;
      padding: 0px !important;
    }
    .ant-collapse-header {
      padding: 0px !important;
    }
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    margin: 16px 0;
    border-radius: 0.5rem;
    border: 1px solid
      ${({ $registered, $isOpen, theme }) =>
        !$isOpen ? theme.colors.transparent : $registered ? '#52C41A' : '#F5222D'};
    overflow: hidden;
  }
`;

const StyledHeader = styled(MessageBox)`
  border-radius: 0;
  background: ${({ $registered }) =>
    $registered ? 'rgba(82, 196, 26, 0.1)' : 'rgba(245, 34, 45, 0.06)'};

  display: flex;
  flex-direction: column;
`;

const StatusBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

const IconBox = styled(StatusBox)`
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const TitleBox = styled(StatusBox)`
  flex-direction: column;
  gap: 0;
`;

const HeaderBox = styled(StatusBox)`
  flex-wrap: wrap;
  align-items: center;
`;

const DetailsText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: underline;
`;

const TextBox = styled.div`
  display: flex;
  padding: 0 16px 16px 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: ${({ $registered }) =>
    $registered ? 'rgba(82, 196, 26, 0.1)' : 'rgba(245, 34, 45, 0.06)'};
  white-space: pre-line;
`;

const StyledPanel = styled(Panel)`
  padding: 0;
  border: none;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-top: 3px;
`;

const Text = styled.p`
  margin-bottom: 0;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-start;

  ${smallBreakpoint} {
    margin-left: 42px;
  }
`;

const TimelineBox = styled.div`
  padding: 7px 3px 0 7px;
`;

function Message({
  isNorthDakota,
  registered,
  disableCollapse,
  contact: contactFromProps,
  isFanOut,
}) {
  const [activeKey, setActiveKey] = React.useState(['1']);
  const { t } = useTranslation();
  const { contactFormResult, selectedVoter } = useSelector(state => state.voterRegistration);
  const contact = contactFromProps || contactFormResult;
  const votingInfo = contact?.voting_info || contactFormResult.voting_info;
  const sameDayReg = votingInfo?.same_day_registration?.toLowerCase() === 'yes';
  // This variable is used to designate a voter who lives in a state where same-day registration
  // is allowed and who is not yet registered to vote.
  const sameDayNonReg = sameDayReg && !registered;
  const today = dayjs();
  // MTS - Leaving this here for debugging purposes
  // const today = dayjs('10-25-22', DATE_FORMAT);
  const afterGenRegDeadline = today.isAfter(dayjs(votingInfo.online_reg_deadline, DATE_FORMAT));

  function getDeadline() {
    const displayFormat = 'MMM D';
    if (today.isBefore(dayjs(votingInfo.online_primary_reg_deadline, DATE_FORMAT))) {
      return dayjs(votingInfo.online_primary_reg_deadline, DATE_FORMAT).format(displayFormat);
    }
    return dayjs(votingInfo.online_reg_deadline, DATE_FORMAT).format(displayFormat);
  }

  function getTitle() {
    if (isNorthDakota) {
      return t('voting_information.registered_nd');
    }
    return registered ? t('voting_information.registered') : t('voting_information.unregistered');
  }

  function getNotRegisteredText(state) {
    const onlinePrimaryRegDate = votingInfo.online_primary_reg_deadline;
    const onlineGeneralRegDate = votingInfo.online_reg_deadline;

    if (!onlineGeneralRegDate && !onlinePrimaryRegDate) {
      return t('voting_information.onlineRegistrationIsNotAvailableMessage', {
        date: getNoOnlineRegistrationAvailableDeadlineDate(votingInfo),
        state: t(`states.${info.state?.toUpperCase()}`),
      });
    }

    if (sameDayNonReg && afterGenRegDeadline) {
      return t('voting_information.sameDayReg', {
        state: t(`states.${state?.toUpperCase()}`),
      });
    }
    return t('voting_information.notRegisteredMessage', {
      date: getDeadline(),
      state: t(`states.${info.state?.toUpperCase()}`),
    });
  }

  const getButtonText = React.useCallback(() => {
    if (sameDayNonReg && afterGenRegDeadline) {
      return t('button.learn_more');
    }
    return t('voting_information.register_to_vote');
  }, [sameDayNonReg, afterGenRegDeadline, t]);

  const getButtonLink = React.useCallback(() => {
    if (sameDayNonReg && afterGenRegDeadline) {
      return votingInfo.source_link1;
    }

    if (votingInfo.online_reg_link) {
      return votingInfo.online_reg_link;
    }

    if (votingInfo.accepts_national_reg_form) {
      return votingInfo.national_reg_link;
    }

    if (!votingInfo.accepts_national_reg_form && votingInfo.paper_reg_link) {
      return votingInfo.paper_reg_link;
    }

    return votingInfo.source_link1;
  }, [afterGenRegDeadline, sameDayNonReg, votingInfo]);

  const info = getInfo(contact, selectedVoter);
  const title = getTitle();

  const registeredText = (
    <>
      {t('voting_information.who')} {info.name}, {info.age}{' '}
      {t('check_registration.search_results.years_old')}
      <br />
      {t('voting_information.at')} {info.address}
    </>
  );

  const notRegisteredText = getNotRegisteredText(info.state);

  const onChange = React.useCallback(
    keys => !disableCollapse && setActiveKey(keys),
    [disableCollapse],
  );

  const RegisterButton = React.useMemo(
    () =>
      !registered && (
        <ButtonArea $isOpen={activeKey?.length}>
          <Button
            forwardedAs="a"
            href={getButtonLink()}
            rel="noopener noreferrer"
            style={
              ({
                display: 'inline-flex',
                minWidth: 180,
                width: 'initial',
              },
              isFanOut && {
                background: 'white',
                color: theme.colors.blue,
                textTransform: 'capitalize',
              })
            }
            target="_blank"
            onClick={e => e.stopPropagation()}
          >
            {getButtonText()}
          </Button>
        </ButtonArea>
      ),
    [getButtonText, registered, getButtonLink, activeKey, isFanOut],
  );

  const DetailsButton = React.useMemo(
    () => (
      <DetailsText>
        {activeKey?.length
          ? t('voting_information.hide_details')
          : t('voting_information.view_details')}
      </DetailsText>
    ),
    [activeKey, t],
  );

  return (
    <>
      <CollapseAntdStyles />
      <StyledCollapse
        $registered={registered}
        $isOpen={activeKey?.length}
        onChange={onChange}
        expandIconPosition={'right'}
        activeKey={activeKey}
        ghost
      >
        <StyledPanel
          header={
            <StyledHeader $registered={registered} $isOpen={activeKey?.length}>
              <IconBox>
                <StatusBox>
                  <HeaderBox>
                    <IconBox>
                      {registered ? <CircleCheck /> : <CircleX />}
                      <TitleBox
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'column',
                        }}
                      >
                        <Title>{title}</Title>
                        {!disableCollapse && !registered && DetailsButton}
                      </TitleBox>
                    </IconBox>
                    {RegisterButton}
                  </HeaderBox>
                </StatusBox>
                {!disableCollapse && registered && DetailsButton}
              </IconBox>
            </StyledHeader>
          }
          key={'1'}
          showArrow={false}
        >
          <>
            <TextBox $registered={registered}>
              {registered ? (
                <Text>{registeredText}</Text>
              ) : (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: notRegisteredText,
                  }}
                />
              )}
            </TextBox>
            <TimelineBox>
              <Timeline contact={contact} registered={registered} votingInfo={votingInfo} />
            </TimelineBox>
          </>
        </StyledPanel>
      </StyledCollapse>
    </>
  );
}

Message.propTypes = {
  contact: PropTypes.object,
  disableCollapse: PropTypes.bool,
  isFanOut: PropTypes.bool,
  isNorthDakota: PropTypes.bool,
  registered: PropTypes.bool.isRequired,
};

Message.defaultProps = {
  isNorthDakota: false,
};

export default Message;
