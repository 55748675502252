import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { connect, Field, getIn } from 'formik';
import { ErrorText, InputWrapper, Label, inputStyle } from './sharedStyles';
import MaskedInput from './MaskedInput';
import SelectInput from './SelectInput';
import RadioGroup from './RadioGroup';

// icons
import Calendar from '../icons/Calendar';
import Email from '../icons/Email';
import House from '../icons/House';
import Location from '../icons/Location';
import Person from '../icons/Person';
import Phone from '../icons/Phone';

export const StyledInput = styled(Field)`
  ${inputStyle}

  &&& {
    ${({ $withoutIcon }) => $withoutIcon && `padding-left: 16px`}
  }
`;

export const Red = styled.span`
  color: ${({ theme }) => theme.colors.red};
`;

export const Relative = styled.div`
  position: relative;

  svg path {
    fill-opacity: 0.3;
    transition: fill-opacity 150ms ease-out;
  }

  input:focus + div svg path {
    fill-opacity: 1;
  }
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  left: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

function Input({
  icon,
  formik,
  label,
  mask,
  options,
  radioButtons,
  name,
  placeholder,
  required,
  wrapperStyle,
  ...rest
}) {
  const iconMap = {
    calendar: <Calendar />,
    email: <Email />,
    house: <House />,
    location: <Location />,
    person: <Person />,
    phone: <Phone />,
  };
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  const hasError = touched && error;
  const thisIcon = iconMap[icon] || null;

  const inputProps = {
    $hasError: hasError,
    id: name,
    name,
    placeholder: placeholder ? placeholder : label,
    ...rest,
  };

  function renderInput() {
    const maskedFieldValue = formik.values[name];

    if (radioButtons) {
      return <RadioGroup radioButtons={radioButtons} value={maskedFieldValue} {...inputProps} />;
    }
    if (options) {
      return (
        <SelectInput
          $withoutIcon={!thisIcon}
          options={options}
          value={maskedFieldValue}
          {...inputProps}
        />
      );
    }
    if (mask) {
      return (
        <MaskedInput
          $withoutIcon={!thisIcon}
          mask={mask}
          value={maskedFieldValue}
          {...inputProps}
        />
      );
    }

    return (
      <StyledInput
        $withoutIcon={!thisIcon}
        {...inputProps}
        // Google Autofill ignoring [autoComplete='off'] property
        // As a solution to avoid autocomplete we can use "new-password" value
        // issue: https://gist.github.com/niksumeiko/360164708c3b326bd1c8
        autoComplete={inputProps.autoComplete === 'off' ? 'new-password' : inputProps.autoComplete}
      />
    );
  }

  return (
    <InputWrapper style={wrapperStyle}>
      <Label htmlFor={name}>
        {label} {required && <Red>*</Red>}
      </Label>
      <Relative>
        {renderInput()}
        {thisIcon && <IconWrapper>{thisIcon}</IconWrapper>}
      </Relative>
      {hasError && <ErrorText children={error} />}
    </InputWrapper>
  );
}

Input.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    values: PropTypes.any.isRequired,
  }).isRequired,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  required: PropTypes.bool,
  wrapperStyle: PropTypes.object,
};

Input.defaultProps = {
  icon: '',
  mask: null,
  options: null,
  placeholder: null,
  required: false,
  wrapperStyle: {},
};

export default connect(Input);
