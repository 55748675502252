import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '../../../../services/analytics';
import { users } from '../../../../services/api';
import ImpactiveButton from '../../../ImpactiveButton';
import ScriptMessageInput from './ScriptMessageInput';
import buildEmail from './buildEmail';
import {
  Top,
  TopLeft,
  Title,
  EmailInfo,
  EmailInfoLabel,
  TopRight,
  Step,
  Section,
  SectionTitle,
  ActionButtons,
} from './styles';

const CONTACTS_PER_EMAIL = 50;

// Helper component which is rendered when contacts are selected, and bulk email is ready to be sent
const BulkSendingScreen = ({
  contactList,
  scripts,
  userActivity,
  googleUser,
  skippedInAppEmail,
  onCompleted,
  onShowActivityCompletedModal,
}) => {
  const { t } = useTranslation();
  const [messageBody, setMessageBody] = useState('');
  const [scriptId, setScriptId] = useState(null);

  useEffect(() => {
    track('VIEW_SEND_BULK_MESSAGE_SCREEN');
  }, []);

  // Bulk sending helpers - used to split large bcc lists into multiple emails.
  const validContacts = useMemo(() => {
    return contactList.filter(contact => contact.email);
  }, [contactList]);
  const totalBatches = Math.ceil(validContacts.length / CONTACTS_PER_EMAIL);
  const [batchPage, setBatchPage] = useState(0);
  const finishedSending = batchPage === totalBatches;

  // Callback when send email to selected contacts
  const handleSendMessage = async () => {
    // Only use contacts from current batch
    const contactIds = validContacts
      .slice(batchPage * CONTACTS_PER_EMAIL, (batchPage + 1) * CONTACTS_PER_EMAIL)
      .map(c => c.id);

    // Store sent messages
    await users.bulkUpdateMessageSent(contactIds, {
      message: {
        activity_script_id: scriptId,
        body: messageBody,
        lock: false,
        rate_limit: false,
        type: 'EmailMessage',
        user_activity_id: userActivity.id,
        visible: true,
      },
    });
    setBatchPage(batchPage + 1);
  };

  function handleSendEmail() {
    const email = buildEmail({
      bcc: validContacts.map(contact => contact.email).join(', '),
      from: `${googleUser.name} <${googleUser.email}>`,
      message: messageBody,
      subject: 'New Impactive Message',
      to: '',
    });
    window.gapi.client.gmail.users.messages
      .send({
        resource: {
          raw: email,
        },
        userId: 'me',
      })
      .then(res => {
        handleSendMessage();
      })
      .catch(err => console.error({ err }));
  }

  // Prompt user to mark activity as completed when all bulk emails are sent
  useEffect(() => {
    if (!userActivity.completed && finishedSending) {
      onShowActivityCompletedModal();
    }
    // eslint-disable-next-line
  }, [finishedSending, userActivity.completed, onShowActivityCompletedModal]);

  useEffect(() => {
    if (finishedSending) {
      onCompleted();
    }
  }, [finishedSending, onCompleted]);

  // creates "paginated" mailto link, split into batches of CONTACTS_PER_EMAIL contacts until the list is exhausted
  const mailToLink = React.useMemo(() => {
    const subject = encodeURI(t('common.new_outvote_message'));
    const encodedBody = encodeURIComponent(messageBody);
    const recipients = validContacts
      .map(c => c.email)
      .slice(batchPage * CONTACTS_PER_EMAIL, (batchPage + 1) * CONTACTS_PER_EMAIL);

    return `mailto:?bcc=${recipients.join(',')}&subject=${subject}&body=${encodedBody}`;
  }, [t, messageBody, validContacts, batchPage]);

  if (finishedSending) {
    return null;
  }

  return (
    <>
      <Top>
        <TopLeft>
          <Title>{t('activity.f2f.emailing.send_to_all')}</Title>
          {googleUser && googleUser.email && (
            <EmailInfo>
              <EmailInfoLabel>{t('activity.f2f.emailing.from')}:</EmailInfoLabel>
              {googleUser.email}
            </EmailInfo>
          )}
          {contactList.length !== validContacts.length && (
            <p className="text-error">
              {t('activity.f2f.emailing.bulk_emails_missing', {
                selected: contactList.length,
                valid: validContacts.length,
              })}
            </p>
          )}
        </TopLeft>
        <TopRight>
          <Step>
            {t('activity.f2f.emailing.bulk_counter', {
              count: validContacts.length,
            })}
          </Step>
        </TopRight>
      </Top>

      <Section>
        <ScriptMessageInput
          scripts={scripts}
          message={messageBody}
          setScriptId={setScriptId}
          setMessage={setMessageBody}
          userActivity={userActivity}
        />
      </Section>

      <Section>
        <SectionTitle>{t('activity.f2f.emailing.ready_to_send')}</SectionTitle>
        <div>{t('activity.f2f.emailing.actions_description')}</div>
        <ActionButtons>
          {!skippedInAppEmail && (
            <ImpactiveButton size="large" disabled={!messageBody} onClick={handleSendEmail}>
              {t('activity.f2f.emailing.send_with_outvote')}
            </ImpactiveButton>
          )}
          <a target="_blank" rel="noreferrer" href={mailToLink} onClick={handleSendMessage}>
            <ImpactiveButton
              size="large"
              secondary
              disabled={!messageBody}
              onClick={handleSendMessage}
            >
              {t('activity.f2f.emailing.batch_send')}
              {totalBatches > 1 ? `(${batchPage + 1} / ${totalBatches})` : ``}
            </ImpactiveButton>
          </a>
        </ActionButtons>
      </Section>
    </>
  );
};

BulkSendingScreen.propTypes = {
  contactList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  googleUser: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  onCompleted: PropTypes.func.isRequired,
  onShowActivityCompletedModal: PropTypes.func.isRequired,
  scripts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skippedInAppEmail: PropTypes.bool,
  userActivity: PropTypes.shape({
    activity: PropTypes.shape({
      campaign: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
    completed: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default BulkSendingScreen;
