import * as React from 'react';
import PropTypes from 'prop-types';

import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import MobileHero from './MobileHero';
import ReferralForm from './ReferralForm';

import { track } from '@web/services/analytics';
import useContactForm from '@web/components/VoterRegistration/hooks/useContactForm';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';

function Info(props) {
  const { t } = useTranslation();
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { activity, team, isFanOut } = props;
  const { initialValues, schemas, fanOutRadioButtons } = useContactForm(
    contactFormResult,
    activity,
  );
  const isMobile = useIsMobile();

  React.useEffect(() => {
    track('VR_VIEW_REFERRAL_FORM', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
    });
    if (isFanOut) {
      amplitudeTrack({ activity, name: 'FAN_OUT_VIEW_REFERRAL_FOR', team });
    }
    // eslint-disable-next-line
  }, []);

  const text = (
    <>
      <Title>
        {[784, 3876].includes(activity?.campaign?.id)
          ? t('check_registration.referral.wwavTitle')
          : isFanOut
          ? t('check_registration.referral.fanOutTitle')
          : t('check_registration.referral.title')}
      </Title>
      <Description>
        {[784, 3876].includes(activity?.campaign?.id)
          ? t('check_registration.referral.wwavDescription')
          : isFanOut
          ? t('check_registration.referral.fanOutDescription')
          : t('check_registration.referral.formDescription')}
      </Description>
    </>
  );

  return (
    <>
      {isMobile ? (
        <MobileHero isFanOut={isFanOut} isForm>
          {text}
        </MobileHero>
      ) : (
        text
      )}
      <ReferralForm
        initialValues={initialValues.referralForm}
        schema={isFanOut ? schemas.fanOutReferralForm : schemas.referralForm}
        fanOutRadioButtons={fanOutRadioButtons}
        submitButtonChildren={
          [784, 3876].includes(activity?.campaign?.id)
            ? t('check_registration.referral.wwavFormButton')
            : isFanOut
            ? t('check_registration.referral.fanOutFormButton')
            : t('check_registration.referral.formButton')
        }
        {...props}
      />
    </>
  );
}

Info.propTypes = {
  activity: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  team: PropTypes.object,
};

Info.defaultProps = {
  team: null,
};

export default Info;
