import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckMark from './CheckMark';

const StyledIncentiveItem = styled.li`
  align-items: center;
  display: flex;
`;

function IncentiveItem({ children, ...rest }) {
  return (
    <StyledIncentiveItem {...rest}>
      <CheckMark />
      {children}
    </StyledIncentiveItem>
  );
}

IncentiveItem.propTypes = {
  children: PropTypes.any,
};

IncentiveItem.defaultProps = {
  children: null,
};

export default IncentiveItem;
