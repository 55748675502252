import * as React from 'react';
import PropTypes from 'prop-types';

function LinkedIn(props) {
  return (
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49252 5.33958H8.07169V6.6243C8.44322 5.88542 9.396 5.22153 10.8272 5.22153C13.571 5.22153 14.2224 6.69236 14.2224 9.39097V14.3889H11.4446V10.0056C11.4446 8.46875 11.0731 7.60208 10.1272 7.60208C8.81544 7.60208 8.2703 8.53611 8.2703 10.0049V14.3889H5.49252V5.33958ZM0.729329 14.2708H3.50711V5.22153H0.729329V14.2708ZM3.90502 2.27083C3.90513 2.50366 3.85895 2.73419 3.76918 2.94902C3.67941 3.16385 3.54784 3.35869 3.38211 3.52222C3.04627 3.85599 2.5917 4.04281 2.11822 4.04167C1.64557 4.04135 1.19205 3.855 0.855718 3.52292C0.690587 3.35883 0.559458 3.16377 0.469846 2.94891C0.380233 2.73405 0.333898 2.50363 0.333496 2.27083C0.333496 1.80069 0.520996 1.35069 0.856413 1.01875C1.19244 0.686221 1.64616 0.499792 2.11891 0.5C2.59252 0.5 3.04669 0.686806 3.38211 1.01875C3.71683 1.35069 3.90502 1.80069 3.90502 2.27083Z"
        fill="#6CCBDA"
      />
    </svg>
  );
}

LinkedIn.propTypes = {
  height: PropTypes.number,
};

LinkedIn.defaultProps = {
  height: 15,
};

export default LinkedIn;
