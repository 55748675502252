import * as React from 'react';
import PropTypes from 'prop-types';

function Facebook(props) {
  return (
    <svg viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.85882 18V9.55388H0.625488V6.51287H2.85882V3.91545C2.85882 1.87438 4.17806 0 7.21786 0C8.44863 0 9.35873 0.11799 9.35873 0.11799L9.28701 2.95778C9.28701 2.95778 8.35886 2.94874 7.34602 2.94874C6.24982 2.94874 6.07419 3.45391 6.07419 4.29237V6.51287H9.37415L9.23057 9.55388H6.07419V18H2.85882Z"
        fill="#6CCBDA"
      />
    </svg>
  );
}

Facebook.propTypes = {
  height: PropTypes.number,
};

Facebook.defaultProps = {
  height: 18,
};

export default Facebook;
