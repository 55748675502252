import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Description, Title } from '../../TextStyles';
import IncentiveItem from '../../ChooseFlow/Incentives/IncentiveItem';
import ShareLink from './ShareLink';

import { useSelector } from 'react-redux';
import { largeBreakpoint } from '../../breakpoints';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const StyledShare = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 40px;
  ${largeBreakpoint} {
    flex-direction: row;
  }
`;

const Incentives = styled.ul`
  background-color: rgba(108, 203, 218, 0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  min-width: 280px;
  padding: 1rem;
`;

const Left = styled.div`
  flex: 1;
`;

const StyledTitle = styled(Title)`
  font-size: 24px;
  font-weight: 600;
`;

function Share({ activity }) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const queryParams = new URLSearchParams(search);
  const activeFormSection = queryParams.get('start');
  const activeLanguage = queryParams.get('language');

  let referralLink = contactFormResult?.referral_link || '';

  if (activeFormSection === '1') {
    referralLink += '?start=1';
  }

  if (activeLanguage) {
    const separator = referralLink.includes('?') ? '&' : '?';
    referralLink += `${separator}language=${activeLanguage}`;
  }
  const hasIncentives = activity.activity_incentives_attributes.length > 0;

  return (
    <StyledShare>
      <Left>
        <StyledTitle>{t('voting_information.share.title')}</StyledTitle>
        <Description>{t('voting_information.share.description')}</Description>
        <ShareLink activity={activity} referralLink={referralLink} />
      </Left>
      {hasIncentives && (
        <Incentives>
          {activity.activity_incentives_attributes.map(el => (
            <IncentiveItem key={el.id}>{el.name}</IncentiveItem>
          ))}
        </Incentives>
      )}
    </StyledShare>
  );
}

Share.propTypes = {
  activity: PropTypes.object.isRequired,
};

Share.defaultProps = {};

export default Share;
