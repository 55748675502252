import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import {
  setContactFormResult,
  setIsCheckedVoterRegistration,
} from '@web/reducers/voterRegistration';
import Status from '@web/constants/contactRegistrationStatusTypes';

import { fanOut } from '@web/services/api';

export const useMyStats = ({ activity, state, isGetReferral, isCheckedVoterRegistration }) => {
  const { t } = useTranslation();
  const [statsLoading, setStatsLoading] = useState(false);
  const [myStats, setMyStats] = useState({ contact: null, entries: 0, recruits: 0, status: '' });

  const { contactFormResult } = useSelector(state => state.voterRegistration);

  const phone = useMemo(() => contactFormResult?.phone, [contactFormResult?.phone]);

  useEffect(() => {
    if (!phone || statsLoading || state) return;

    setStatsLoading(true);
    fanOut
      .getMyStats({ activity_id: activity?.id, campaign_id: activity?.campaign?.id, phone })
      .then(({ data }) => {
        setMyStats({
          contact: data,
          entries: data.entries_earned,
          recruits: data.recruits_count,
          status: data.latest_registration_status,
        });
      })
      .catch(error => {
        message.error(
          error?.response?.data?.message || error?.message || t('common.something_went_wrong'),
        );
        console.error(error);
      })
      .finally(() => setStatsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, activity, state, isGetReferral, isCheckedVoterRegistration, t]);

  return { myStats, statsLoading };
};

export const useRestoreContact = ({ activitySlug, campaignSlug, activity, referralHash }) => {
  const [isRestoringContact, setIsRestoringContact] = useState(false);
  const [isFanOutReferralFlow, setIsFanOutReferralFlow] = useState(false);
  const dispatch = useDispatch();
  const [cookies] = useCookies();

  const fanOutCookies = useMemo(() => cookies?.fan_out_auth, [cookies?.fan_out_auth]);

  useEffect(() => {
    if (isRestoringContact) return;

    setIsRestoringContact(true);
    // get data from local storage
    const contact = JSON.parse(localStorage.getItem('fan_out_contact'));
    const activities = JSON.parse(localStorage.getItem('fan_out_contact_activities') || '[]');
    // find current activity in local storage
    const localActivity = activities.find(
      a => activity.id === a.id && activity.campaign.id === a.campaign.id,
    );

    // find current activity in cookies
    const cookiesActivity = fanOutCookies?.[0]?.activities?.find(
      // convert activity_id and campaign_id to number for correct comparison
      a => activity.id === Number(a.activity_id) && activity.campaign.id === Number(a.campaign_id),
    );

    // always use cookies data if it exists
    const restoredActivity = fanOutCookies ? cookiesActivity : localActivity;

    // restore data from local storage if contact exists
    if (contact) {
      dispatch(
        setContactFormResult({
          activity,
          contact: {
            ...contact,
            referral_link: restoredActivity?.referral_link || null,
            registration_status: restoredActivity?.registration_status || Status.UNCONFIRMED,
          },
        }),
      );
      dispatch(setIsCheckedVoterRegistration(!!restoredActivity?.registration_status));
    }
    // add timeout to give time to dispatch to update states
    setTimeout(() => {
      setIsRestoringContact(false);
      if (!!referralHash && !restoredActivity?.registration_status) {
        setIsFanOutReferralFlow(true);
      }
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySlug, campaignSlug, activity]);

  return { isFanOutReferralFlow, isRestoringContact };
};

export const useGetVotingPlanReferralLink = ({ campaignId, phone }) => {
  const [votingPlanReferralLink, setVotingPlanReferralLink] = useState(null);
  const [votingPlanReferralLinkLoading, setVotingPlanReferralLinkLoading] = useState(false);

  useEffect(() => {
    if (!campaignId || !phone) return;

    setVotingPlanReferralLinkLoading(true);

    fanOut
      .getVotingPlanReferralLink({ campaign_id: campaignId, phone })
      .then(({ data }) => {
        setVotingPlanReferralLink(data.url);
      })
      .finally(() => setVotingPlanReferralLinkLoading(false));
  }, [campaignId, phone]);

  return { votingPlanReferralLink, votingPlanReferralLinkLoading };
};

export const useGetReferrer = ({ activitySlug, campaignSlug, referralHash }) => {
  const [referrerLoading, setReferrerLoading] = useState(false);
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    if (referralHash) {
      setReferrerLoading(true);

      axios
        .get(
          `/api/v2/client/voter_registrations/campaigns/${campaignSlug}/referrer/${referralHash}`,
        )
        .then(({ data }) => {
          setReferrer(data);
        })
        .finally(() => setReferrerLoading(false));
    }
  }, [activitySlug, campaignSlug, referralHash]);

  return { referrer, referrerLoading };
};

export const useMyActivityRedirect = () => {
  const history = useHistory();
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const tabParam = useMemo(() => queryParams.get('tab'), [queryParams]);

  useEffect(() => {
    if (tabParam === 'activity') {
      queryParams.delete('tab');
      history.replace({
        search: queryParams.toString(),
        state: 'activity',
      });
    }

    return;
  }, [tabParam, queryParams, history]);
};

export const useClearStateOnReload = () => {
  const history = useHistory();

  useEffect(() => {
    const isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? 'onpagehide' : 'onbeforeunload';

    window[eventName] = () => history.replace({ state: '' });

    return () => (window[eventName] = null);
  }, [history]);
};
