import * as React from 'react';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import i18n from '@web/components/i18n';
import {
  dateFormat,
  phoneRegExp,
} from '@web/components/VoterRegistration/components/common/utils/schema';

export const ReferralFormSchema = t =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('check_registration.invalid_email'))
      .required(t('idvoters.validation.emailRequired')),
    firstName: yup.string().required(t('idvoters.validation.firstname')),
    lastName: yup.string().required(t('idvoters.validation.lastname')),
    phone: yup
      .string()
      .matches(phoneRegExp, t('check_registration.invalid_phone'))
      .required(t('idvoters.validation.phoneRequired')),
    showOnLeaderboard: yup.bool(),
    smsOptIn: yup.bool(),
    terms: yup
      .bool()
      .required(t('idvoters.validation.terms'))
      .oneOf([true], t('idvoters.validation.terms')),
  });

export const FanOutReferralFormSchema = yup.object().shape({
  age_group: yup
    .string()
    .required(i18n.t('idvoters.validation.age_group'))
    .oneOf(['13-17', '18+'], i18n.t('idvoters.validation.age_group')),
  email: yup
    .string()
    .email(i18n.t('check_registration.invalid_email'))
    .required(i18n.t('idvoters.validation.emailRequired')),
  firstName: yup.string().required(i18n.t('idvoters.validation.firstname')),
  lastName: yup.string().required(i18n.t('idvoters.validation.lastname')),
  phone: yup
    .string()
    .matches(phoneRegExp, i18n.t('check_registration.invalid_phone'))
    .required(i18n.t('idvoters.validation.phoneRequired')),
  showOnLeaderboard: yup.bool(),
  smsOptIn: yup.bool(),
  terms: yup
    .bool()
    .required(i18n.t('idvoters.validation.terms'))
    .oneOf([true], i18n.t('idvoters.validation.terms')),
});

const contactFormValues = t =>
  yup.object().shape({
    address: yup.string().required(t('idvoters.validation.address')),
    date_of_birth: yup
      .date()
      .typeError(t('idvoters.validation.valid_dob'))
      .required(t('idvoters.validation.dob'))
      .min(dayjs().subtract(120, 'year'), t('idvoters.validation.valid_dob'))
      .max(dayjs().subtract(17, 'year'), t('idvoters.validation.max_date', { age: 18 })),
  });

const fanOutContactFormValues = yup.object().shape({
  address: yup.string().required(i18n.t('idvoters.validation.address')),
  date_of_birth: yup
    .date()
    .typeError(i18n.t('idvoters.validation.valid_dob'))
    .required(i18n.t('idvoters.validation.dob'))
    .min(dayjs().subtract(120, 'year'), i18n.t('idvoters.validation.valid_dob'))
    // Election day 2024 is 11/05/2024
    // We want to make sure that the date of birth is at least 18 years before that
    .max(dayjs('11/05/2006'), i18n.t('idvoters.validation.max_date', { age: 18 })),
});

export const FriendLookUpFormSchema = t =>
  yup.object().shape({
    ageRange: yup.string().required(t('idvoters.validation.age')),
    firstName: yup.string().required(t('idvoters.validation.firstname')),
    lastName: yup.string().required(t('idvoters.validation.lastname')),
    stateAbbv: yup.string().required(t('idvoters.validation.state')),
  });

export const ContactFormSchema = t => ReferralFormSchema(t).concat(contactFormValues(t));
export const FanOutContactFormSchema = t => ReferralFormSchema(t).concat(fanOutContactFormValues);

function useContactForm(initialContact = {}, activity) {
  const [contact, setContact] = React.useState(initialContact);
  const { t } = useTranslation();

  function buildAddress() {
    if (contact?.address) {
      return contact.address;
    }
    return '';
  }

  const fanOutRadioButtons = [
    {
      label: '13-17',
      value: '13-17',
    },
    {
      label: '18+',
      value: '18+',
    },
  ];

  const contactFormInitialValues = {
    address: buildAddress(),
    city: contact?.city || '',
    countyName: contact?.county_name || '',
    date_of_birth: contact?.date_of_birth || '',
    email: contact?.email || '',
    firstName: contact?.first_name || '',
    lastName: contact?.last_name || '',
    phone: contact?.phone ? contact?.phone.replace(/\D/g, '') : '',
    showOnLeaderboard: activity?.show_contacts_on_leaderboard_by_default ?? true,
    smsOptIn: true,
    stateAbbrev: contact?.state_abbrev || '',
    terms: true,
    unitNumber: contact?.unit_number || '',
    zipCode: contact?.zip_code || '',
  };

  const referralFormInitialValues = {
    age_group: contact?.age_group || '',
    email: contact?.email || '',
    firstName: contact?.first_name || '',
    lastName: contact?.last_name || '',
    phone: contact?.phone || '',
    showOnLeaderboard: activity?.show_contacts_on_leaderboard_by_default ?? true,
    smsOptIn: true,
    terms: true,
  };

  const friendLookUpFormInitialValues = {
    ageRange: '18-255',
    firstName: '',
    lastName: '',
    stateAbbv: undefined,
  };

  return {
    contact,
    dateFormat,
    fanOutRadioButtons,
    initialValues: {
      contactForm: { ...contactFormInitialValues },
      friendLookUpForm: { ...friendLookUpFormInitialValues },
      referralForm: { ...referralFormInitialValues },
    },
    schemas: {
      contactForm: ContactFormSchema(t),
      fanOutContactForm: FanOutContactFormSchema,
      fanOutReferralForm: FanOutReferralFormSchema,
      friendLookUpForm: FriendLookUpFormSchema(t),
      referralForm: ReferralFormSchema(t),
    },
    setContact,
  };
}

export default useContactForm;
