import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Clipboard from 'react-clipboard.js';
import { message } from 'antd';

import Button from '@web/components/VoterRegistration/newComponents/Button';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import { track } from '@web/services/analytics';
import sendError from '@web/utils/sendError';
import { colors } from '@web/styles/theme';
import ArrowUp from '@web/components/FanOut/components/icons/ArrowUp';
import Copy from '@web/components/FanOut/components/icons/Copy';
import ShareIcon from '@web/components/VoterRegistration/newComponents/icons/Share';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  align-items: flex-start;
  flex-direction: row;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 4px 0px 0px ${({ theme }) => theme.colors.blue};

  ${smallBreakpoint} {
    flex-direction: column;
    align-items: center;
    padding: 16px;
    align-items: center;
    gap: 18px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DescriptionContainer = styled(Container)`
  display: flex;
  gap: 18px;

  ${smallBreakpoint} {
    align-items: center;
    justify-content: center;
  }
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

const QRCodeContainer = styled(Container)`
  align-items: center;
  gap: 16px;
`;

const RecruitmentContainer = styled(Container)`
  align-items: center;
`;

const RecruitmentText = styled(Text)`
  line-height: 1rem;
`;

const ShareTitle = styled(Text)`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;

  ${smallBreakpoint} {
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
  }
`;

const ShareDescription = styled(Text)`
  ${smallBreakpoint} {
    text-align: center;
    color: ${({ theme }) => theme.colors.blackL32};
    font-size: 0.875rem;
    line-height: 1.125rem; /* 128.571% */
  }
`;

const ShareNote = styled(Text)`
  font-size: 0.8rem;
  line-height: 1rem;

  ${smallBreakpoint} {
    text-align: center;
    color: ${({ theme }) => theme.colors.blackL32};
    font-size: 0.75rem;
  }
`;

const LinkContainer = styled(Container)`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${smallBreakpoint} {
    flex-direction: column;
    gap: 18px;
  }
`;

const LinkClipboard = styled(Clipboard)`
  display: flex;
  padding: 20px 16px;
  width: 250%;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  border: 1px solid rgba(57, 62, 91, 0.2);
  height: 64px;

  ${smallBreakpoint} {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    border-color: rgba(57, 62, 91, 0.5);
  }

  /* Shadow/Default */
  box-shadow: 8px 8px 32px 0px rgba(0, 41, 64, 0.06);
`;

const LinkText = styled(Text)`
  font-weight: 700;
`;

const StyledClipboard = styled(Clipboard)`
  display: flex;
  width: 100%;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.trueWhite};
  text-align: center;
  letter-spacing: 0.5px;
  gap: 6px;

  ${smallBreakpoint} {
    width: 100%;
  }

  &&& {
    height: 64px;
    box-shadow: 8px 8px 32px 0px rgba(0, 41, 64, 0.06);
  }
`;

const StyledShareIcon = styled(ShareIcon)`
  margin-right: 6px;
`;

const QRCodeShare = ({ activity, team, shareLink, isFanOut }) => {
  const { t } = useTranslation();
  const { contactFormResult } = useSelector(state => state.voterRegistration);

  const showMobileShare = useMemo(() => navigator?.share, []);

  // use shareLink if it is passed in, otherwise use the referral link from the contact form result
  const referralLink = useMemo(
    () => shareLink || contactFormResult.referral_link || '',
    [shareLink, contactFormResult.referral_link],
  );

  const copyLink = useCallback(() => {
    message.success(t('activity.share.copied_to_clipboard'));
    track('VR_COPY_SHARE_LINK', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
    });
    if (isFanOut) {
      amplitudeTrack({ activity, name: 'FAN_OUT_COPY_SHARE_LINK', team });
    }
  }, [activity, team, isFanOut, t]);

  const mobileShare = useCallback(() => {
    const shareData = {
      text: t('check_registration.referral.fanOutMobileShareText'),
      title: activity.title,
      url: referralLink,
    };
    if (navigator?.canShare && navigator.canShare(shareData) && navigator.share) {
      navigator.share(shareData).catch(err => {
        if (err.toString().includes('AbortError')) {
          // The share api throws this error when a user decides not to share.
          // It isn't a real error.
          return null;
        }
        sendError('FanOut Voter Registration: User encountered error while sharing', { err });
      });
    }
  }, [activity.title, referralLink, t]);

  return (
    <StyledContent>
      <QRCodeContainer>
        <QRCode size={156} value={referralLink} />
        <RecruitmentContainer>
          <ArrowUp />
          <RecruitmentText>{t('check_registration.referral.recruitmentLink')}</RecruitmentText>
        </RecruitmentContainer>
      </QRCodeContainer>
      <DescriptionContainer>
        <ShareTitle>{t('check_registration.referral.shareYourLink')}</ShareTitle>
        <ShareDescription>{t('check_registration.referral.sendLink')}</ShareDescription>
        <ShareNote>{t('check_registration.referral.note')}</ShareNote>
        <LinkContainer>
          <LinkClipboard component="span" data-clipboard-text={referralLink} onSuccess={copyLink}>
            <Copy />
            <LinkText>{referralLink}</LinkText>
          </LinkClipboard>
          {showMobileShare ? (
            <StyledButton onClick={mobileShare} variant="fan_out">
              <StyledShareIcon fill={colors.blue} />
              {t('button.share')}
            </StyledButton>
          ) : (
            <StyledClipboard
              component="span"
              data-clipboard-text={referralLink}
              onSuccess={copyLink}
            >
              <StyledButton onClick={null} variant="fan_out">
                <Copy fill={colors.blue} />
                {t('check_registration.referral.copyButton')}
              </StyledButton>
            </StyledClipboard>
          )}
        </LinkContainer>
      </DescriptionContainer>
    </StyledContent>
  );
};

QRCodeShare.propTypes = {
  activity: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  shareLink: PropTypes.string,
  team: PropTypes.object,
};

QRCodeShare.defaultProps = {};

export default QRCodeShare;
