import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import Header from '@web/components/FanOut/components/Header';
import ScrollToTop from '@web/components/DialerActivity/components/ScrollToTop';
import FanOutActivity from '@web/components/FanOut/components/FanOutActivity';
import Terms from '@web/components/FanOut/components/Terms';
import AllSweepstakes from '@web/components/FanOut/components/AllSweepstakes';
import MyActivity from '@web/components/FanOut/components/MyActivity';
import VoterRegistration from '@web/components/VoterRegistration';
import Footer from '@web/components/FanOut/components/Footer';
import {
  useMyStats,
  useRestoreContact,
  useGetReferrer,
  useMyActivityRedirect,
} from '@web/components/FanOut/hooks/useFanOut';
import { useTrackFanOut, amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';
import { trackPageViewWithDynamicIDs } from '@web/services/analytics';

const StyledFanOutActivity = styled.div`
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  background: ${({ $color }) =>
    $color
      ? $color
      : css`radial-gradient(
      64.5% 64.52% at 97.64% 1.3%,
      rgba(197, 55, 94, 0.2) 0%,
      rgba(197, 55, 94, 0) 100%
    ),
    radial-gradient(
      66.22% 90.3% at 98.11% 98.85%,
      rgba(140, 205, 217, 0.2) 0%,
      rgba(140, 205, 217, 0) 100%
    ),
    radial-gradient(
      64.49% 126.95% at 0% 100%,
      rgba(184, 59, 201, 0.2) 0%,
      rgba(184, 59, 201, 0) 100%
    ),
    radial-gradient(
      86.75% 134.97% at 0% 0%,
      rgba(152, 204, 201, 0.2) 0%,
      rgba(152, 204, 201, 0) 100%
    ),
    radial-gradient(
      53.76% 53.76% at 50% 46.24%,
      rgba(225, 159, 83, 0.2) 0%,
      rgba(225, 159, 83, 0) 100%
    ),
    #fff;`};
`;

const FanOut = ({ activity, team }) => {
  const { activitySlug, campaignSlug } = useParams();
  const { search, state } = useLocation();
  const { referral_hash: referralHash, start } = queryString.parse(search);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showVoterModal, setShowVoterModal] = useState(start === '1');
  const [showFriendSearchModal, setShowFriendSearchModal] = useState(false);

  const { contactFormResult, isCheckedVoterRegistration } = useSelector(
    state => state.voterRegistration,
  );

  const isGetReferral = useMemo(
    () => contactFormResult.referral_link,
    [contactFormResult.referral_link],
  );

  useMyActivityRedirect();

  const { myStats, statsLoading } = useMyStats({
    activity,
    isCheckedVoterRegistration,
    isGetReferral,
    state,
  });

  // restore contact data from local storage
  const { isRestoringContact, isFanOutReferralFlow } = useRestoreContact({
    activity,
    activitySlug,
    campaignSlug,
    referralHash,
    team,
  });

  useTrackFanOut({ activity, name: 'FAN_OUT_ACTIVITY', team });

  React.useEffect(() => {
    trackPageViewWithDynamicIDs({
      fbPixelId: activity?.settings?.pixel_fb_id,
      gaTrackingId: activity?.settings?.pixel_ga_id,
      gtmPixelId: activity?.settings?.pixel_gtm_id,
      snapchatPixelId: activity?.settings?.pixel_sc_id,
    });
  }, [
    activity?.settings?.pixel_fb_id,
    activity?.settings?.pixel_ga_id,
    activity?.settings?.pixel_gtm_id,
    activity?.settings?.pixel_sc_id,
  ]);

  const { referrer, referrerLoading } = useGetReferrer({
    activitySlug,
    campaignSlug,
    referralHash,
  });

  useEffect(() => {
    if (isFanOutReferralFlow) {
      setShowVoterModal(true);
    }
  }, [isFanOutReferralFlow]);

  const openVoterModal = useCallback(() => setShowVoterModal(true), []);

  const openReferralModal = useCallback(() => setShowReferralModal(true), []);

  const openFriendSearchModal = useCallback(() => {
    amplitudeTrack({ activity, name: 'FAN_OUT_FRIEND_LOOK_UP', team });
    setShowFriendSearchModal(true);
  }, [activity, team]);

  const closeModal = useCallback(() => {
    setShowReferralModal(false);
    setShowVoterModal(false);
    setShowFriendSearchModal(false);
  }, []);

  if (referrerLoading || isRestoringContact) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  return (
    <StyledFanOutActivity $color={activity?.settings?.background_color}>
      <Header activity={activity} team={team} />
      <ScrollToTop />
      {state === 'terms' ? (
        <Terms activity={activity} team={team} />
      ) : state === 'activity' ? (
        <MyActivity activity={activity} team={team} openFriendSearchModal={openFriendSearchModal} />
      ) : state === 'sweepstakes' ? (
        <AllSweepstakes activity={activity} referrer={referrer} team={team} />
      ) : (
        <FanOutActivity
          activity={activity}
          closeModal={closeModal}
          modalVoterOpen={showVoterModal}
          modalReferralOpen={showReferralModal}
          openVoterModal={openVoterModal}
          openReferralModal={openReferralModal}
          myStats={myStats}
          statsLoading={statsLoading}
          team={team}
          isFanOut
        />
      )}
      <VoterRegistration
        activity={activity}
        closeModal={closeModal}
        modalVoterOpen={showVoterModal}
        modalReferralOpen={showReferralModal}
        modalFriendSearchOpen={showFriendSearchModal}
        openVoterModal={openVoterModal}
        openReferralModal={openReferralModal}
        referrer={referrer}
        team={team}
        isFanOut
      />
      {!!state && <Footer activity={activity} team={team} />}
    </StyledFanOutActivity>
  );
};

FanOut.propTypes = {
  activity: PropTypes.object.isRequired,
  team: PropTypes.object,
};

FanOut.defaultProps = {
  team: null,
};

export default FanOut;
