import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { media } from '@web/styles/theme';
import { Button } from 'antd';

import JoinButton from '@web/components/common/JoinButton';

const StyledActivityCTA = styled.div`
  color: ${({ theme }) => theme.colors.black};
  padding: 0 25px;
  width: 100%;
`;

const ActivityCTAContainer = styled.div`
  display: flex;
  gap: 30px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.xl} {
    flex-direction: row;
  }
`;

const JoinButtonWrapper = styled.div`
  max-width: 367px;
  width: 100%;
`;

const GetReferralButton = styled(Button)`
  ${media.xl} {
    align-self: start;
  }

  &&& {
    display: inline;
    max-width: 387px;
    width: 100%;
    height: 76px;
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 12px;
    border: none;

    color: ${({ theme }) => theme.colors.white};
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const ReferrerDetails = styled.div`
  max-width: 367px;
  width: 100%;
  text-align: center;
  margin-top: 18px;
`;

const ActivityCTA = ({ handleGetStarted, handleGetReferral, referrer, activity, ...rest }) => {
  const { t } = useTranslation();
  let referrerDetails;

  if (referrer) {
    referrerDetails = t('campaign.activity.referrer').replace('{{fullname}}', referrer.fullname);
  }

  const isActive = activity.aasm_state !== 'closed';

  return (
    <StyledActivityCTA {...rest}>
      <ActivityCTAContainer>
        <JoinButtonWrapper>
          <JoinButton onClick={handleGetStarted} disabled={!isActive}>
            {isActive ? t('campaign.get_started') : 'Closed'}
          </JoinButton>
          {referrer && <ReferrerDetails>{referrerDetails}</ReferrerDetails>}
        </JoinButtonWrapper>
        {isActive && (
          <GetReferralButton data-testid="join-button" onClick={handleGetReferral} type="primary">
            {t('campaign.activity.get_referral_link')}
          </GetReferralButton>
        )}
      </ActivityCTAContainer>
    </StyledActivityCTA>
  );
};

ActivityCTA.propTypes = {
  activity: PropTypes.shape({ aasm_state: PropTypes.string }),
  handleGetReferral: PropTypes.func.isRequired,
  handleGetStarted: PropTypes.func.isRequired,
  referrer: PropTypes.object,
};

export default ActivityCTA;
