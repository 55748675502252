import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Contact, { IconWrapper } from '@web/components/VoterRegistration/newComponents/Contact';
import HoverCard from '@web/components/VoterRegistration/newComponents/HoverCard';

// icons
import Location from '../../../newComponents/icons/Location';

import { useTranslation } from 'react-i18next';

const DURATION = '150ms';

const ShowOnHover = styled.span`
  margin-right: 6px;
  transform: translateY(-1px);
  opacity: 0;
  transition: opacity ${DURATION} ease-out;
`;

const StyledVoterItem = styled(HoverCard)`
  ${IconWrapper} svg path {
    fill-opacity: 0.3;
    transition: fill-opacity ${DURATION} ease-out;
  }
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.blue};
    ${ShowOnHover} {
      opacity: 1;
    }
    ${IconWrapper} svg path {
      fill-opacity: 1;
    }
  }
`;

const Row = styled.div`
  align-items: center;
  display: flex;
`;

function VoterItem({ onSelect, voter, ...rest }) {
  const { addressLine1, addressLine2, age, name } = voter;
  const { t } = useTranslation();
  return (
    <StyledVoterItem
      arrowChildren={<ShowOnHover>{t('check_registration.search_results.this_is_me')}</ShowOnHover>}
      forwardedAs="li"
      onClick={() => onSelect(voter)}
    >
      <Contact age={age} name={name} />
      <Row style={{ marginTop: 20 }}>
        <IconWrapper>
          <Location height={14} />
        </IconWrapper>
        <span>
          {addressLine1} {addressLine2}
        </span>
      </Row>
    </StyledVoterItem>
  );
}

VoterItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  voter: PropTypes.shape({
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    age: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

VoterItem.defaultProps = {};

export default VoterItem;
