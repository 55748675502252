import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Layout from './newComponents/Layout';
import Modal from './newComponents/Modal';
import ProgressBar from './newComponents/ProgressBar';

import { track } from '@web/services/analytics';
import { setSelectedVoter } from '@web/reducers/voterRegistration';
import useFlows, {
  defaultFlow,
  helpOthers,
  fanOutRegistration,
  friendLookUp,
} from './hooks/useFlows';
import useIsMobile from './hooks/useIsMobile';
import useSpecificTranslation from './useSpecificTranslation';
import { vrDefaultColors } from '@web/styles/theme';

const VoterRegistration = ({
  activity,
  closeModal,
  modalReferralOpen,
  modalVoterOpen,
  team,
  isFanOut,
  modalFriendSearchOpen,
  referrer,
}) => {
  const { currentPage, isReferralFlow, selectedFlow, ...flowProps } = useFlows();
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  // Handle default translation language
  useSpecificTranslation();

  React.useEffect(() => {
    if (modalFriendSearchOpen) {
      flowProps.setSelectedFlow(friendLookUp);
    }
    // MTS - We can't pass flowProps into the dep array because it will break the navigation.
    // eslint-disable-next-line
  }, [modalFriendSearchOpen]);

  React.useEffect(() => {
    if (modalReferralOpen) {
      flowProps.setSelectedFlow(helpOthers);
      // We want to go to the "Share" page (2) directly if the registration form was already filled and submitted
      const goToPage = !contactFormResult?.referral_link ? 1 : 2;
      flowProps.setCurrentPage(goToPage);
    }
    // MTS - We can't pass flowProps into the dep array because it will break the navigation.
    // eslint-disable-next-line
  }, [modalReferralOpen]);

  useEffect(() => {
    if (isFanOut && modalVoterOpen) {
      flowProps.setSelectedFlow(referrer ? helpOthers : fanOutRegistration);
    }
    // We can't pass flowProps into the dep array because it will break the navigation.
    // eslint-disable-next-line
  }, [isFanOut, modalVoterOpen, referrer]);

  function checkReferralFlow() {
    if (modalReferralOpen) {
      return true;
    }
    if (isReferralFlow) {
      return true;
    }
    return false;
  }

  const handleClose = useCallback(() => {
    track('VR_MODAL_CLOSED', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
    });
    flowProps.setCurrentPage(0);
    flowProps.setSelectedFlow(defaultFlow);
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageProps = {
    activity,
    handleClose,
    isFanOut,
    referrer,
    team,
    ...flowProps,
  };

  const CurrentPage = selectedFlow[currentPage];

  const referralFlowActive = checkReferralFlow();
  const modalOpen = useMemo(
    () => modalReferralOpen || modalVoterOpen || modalFriendSearchOpen,
    [modalReferralOpen, modalVoterOpen, modalFriendSearchOpen],
  );
  const hideProgress = useMemo(
    () => (currentPage !== 0 && referralFlowActive) || modalFriendSearchOpen,
    [currentPage, referralFlowActive, modalFriendSearchOpen],
  );
  const secondaryCloseButton = useMemo(() => hideProgress && isMobile, [hideProgress, isMobile]);
  const isCommonVRFlow = useMemo(
    () => !isFanOut && !activity.campaign?.headcount_registration_enabled,
    [activity, isFanOut],
  );
  const isCommonVRChooseFlow = useMemo(
    () => isCommonVRFlow && currentPage === 0 && modalVoterOpen,
    [isCommonVRFlow, currentPage, modalVoterOpen],
  );

  const closeHandler = useCallback(() => {
    // HR - clear voter if not on last page (not a VoterInformation modal) for fan out voter modal
    if (selectedFlow.length - 1 !== currentPage && !modalFriendSearchOpen && isFanOut) {
      dispatch(setSelectedVoter(null));
    }
    handleClose();
  }, [handleClose, dispatch, currentPage, selectedFlow, modalFriendSearchOpen, isFanOut]);

  return (
    <Modal
      closeModal={closeHandler}
      modalOpen={modalOpen}
      secondaryCloseButton={secondaryCloseButton}
      isFanOut={isFanOut}
      isCommonVRChooseFlow={isCommonVRChooseFlow}
      activity={activity}
    >
      <Layout>
        {!hideProgress && (
          <ProgressBar
            progressColor={activity.settings.progress_bar_color ?? vrDefaultColors.progressBarColor}
            progress={(currentPage + 1) / selectedFlow.length}
            isFanOut={isFanOut}
          />
        )}
        <CurrentPage {...pageProps} isCommonVRFlow={isCommonVRFlow} />
      </Layout>
    </Modal>
  );
};

VoterRegistration.propTypes = {
  activity: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isFanOut: PropTypes.bool,
  modalFriendSearchOpen: PropTypes.bool,
  modalReferralOpen: PropTypes.bool.isRequired,
  modalVoterOpen: PropTypes.bool.isRequired,
  referrer: PropTypes.object,
  team: PropTypes.object,
};

export default VoterRegistration;
