import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import CloseButton from './CloseButton';
import { Modal as AntModal } from 'antd';

const largeBreakpoint = '@media screen and (min-width: 958px)';
const smallBreakpoint = `@media screen and (max-width: 957px)`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${({ $isFanOut }) => ($isFanOut ? '28px 16px 40px' : '28px 24px 40px')};
  width: 100%;

  ${largeBreakpoint} {
    margin: 32px 32px 42px 48px;
  }
`;

const ModalStyle = createGlobalStyle`
  &&& {
    .vr-modal .ant-modal {
      width: 100vw;
      ${smallBreakpoint} {
        margin: 0;
        min-height: 100vh;
        min-width: 100vw;
        padding-bottom: 0;
        top: 0;
      }
      ${largeBreakpoint} {
        min-width: 880px;
        width: 100%;
      }
    }
    .vr-modal .ant-modal-body{
      box-sizing: border-box;
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
    }
    .vr-modal .ant-modal-content {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      padding: 0;
      width: 100%;
      background: ${({ $color }) => $color};
      ${largeBreakpoint} {
        border-radius: 30px;
        min-height: 100%;
      }
    }
  }
`;

const Modal = ({
  children,
  closeModal,
  modalOpen,
  secondaryCloseButton,
  isFanOut,
  isCommonVRChooseFlow,
  activity,
  ...rest
}) => {
  const color = useMemo(
    () => isCommonVRChooseFlow && activity?.settings?.background_color,
    [isCommonVRChooseFlow, activity?.settings?.background_color],
  );

  return (
    <>
      <ModalStyle $color={color} />
      <AntModal
        bodyStyle={{ padding: 0 }}
        closable={false}
        footer={null}
        visible={modalOpen}
        wrapClassName="vr-modal"
        maskClosable={false}
        {...rest}
      >
        <CloseButton
          aria-label="close"
          onClick={closeModal}
          secondary={secondaryCloseButton}
          isFanOut={isFanOut}
        />
        <Content $isFanOut={isFanOut}>{children}</Content>
      </AntModal>
    </>
  );
};

Modal.propTypes = {
  activity: PropTypes.object,
  children: PropTypes.any,
  closeModal: PropTypes.func.isRequired,
  isCommonVRChooseFlow: PropTypes.bool,
  isFanOut: PropTypes.bool,
  modalOpen: PropTypes.bool.isRequired,
  secondaryCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  secondaryCloseButton: false,
};

export default Modal;
