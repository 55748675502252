import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IconBox } from '@web/components/FanOutVotingPlan/components/PageTitle';
import VotingPlanButton from '@web/components/FanOutVotingPlan/components/VotingPlanButton';
import { useIs800 } from '@web/components/VoterRegistration/hooks/useIsMobile';
import { capitalize } from '@web/utils/string';
import { Wrapper } from '../styles';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const Content = styled.div`
  width: 100%;
  display: flex;
  padding: 80px 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};

  ${smallBreakpoint} {
    padding: 60px 20px;
  }
`;

const TitleText = styled.div`
  margin-top: 32px;
  padding: 0 60px;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: center;
  font-size: 1.75rem;
  line-height: 2rem; /* 114.286% */

  ${smallBreakpoint} {
    font-size: 1.25rem;
    line-height: 1.5rem; /* 120% */
    padding: 0;
  }
`;

const BottomText = styled.div`
  margin: 20px 0 48px 0;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.regular};
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2rem; /* 160% */

  ${smallBreakpoint} {
    font-size: 1rem;
    line-height: 1.5rem; /* 150% */
    margin: 16px 0 48px 0;
  }

  span {
    font-weight: 600;
    text-transform: capitalize;
  }
`;

const Oops = ({ onNextPage, currentPage: { nextButtonText, nextIcon }, referrer, oopsType }) => {
  const { t } = useTranslation();
  const isMobile = useIs800();

  const titleText = useMemo(
    () =>
      oopsType.errorType === 'other_recruiter'
        ? t('fan_out.voting_plan.oops.title.other_recruiter')
        : t('fan_out.voting_plan.oops.title.common'),
    [oopsType, t],
  );

  const descriptionText = useMemo(
    () =>
      oopsType.errorType === 'other_recruiter'
        ? t('fan_out.voting_plan.oops.description.other_recruiter', {
            referrer: capitalize(oopsType.referrer),
          })
        : t('fan_out.voting_plan.oops.description.common', {
            referrer: capitalize(referrer?.fullname),
          }),
    [oopsType, t, referrer],
  );

  const showNextButton = useMemo(() => oopsType.errorType !== 'other_recruiter', [oopsType]);

  return (
    <Wrapper>
      <Content>
        <IconBox icon="⚠️️️" />
        <TitleText>{titleText}</TitleText>
        <BottomText dangerouslySetInnerHTML={{ __html: descriptionText }} />
        {showNextButton && (
          <VotingPlanButton
            onClick={onNextPage}
            text={nextButtonText}
            icon={nextIcon}
            isMobile={isMobile}
            topCompensation
          />
        )}
      </Content>
    </Wrapper>
  );
};

Oops.propTypes = {
  currentPage: PropTypes.shape({
    index: PropTypes.number.isRequired,
    nextButtonText: PropTypes.string,
    nextIcon: PropTypes.node,
    prevButtonText: PropTypes.string,
    prevIcon: PropTypes.node,
  }).isRequired,
  onNextPage: PropTypes.func.isRequired,
  oopsType: PropTypes.shape({
    errorType: PropTypes.string,
    referrer: PropTypes.string,
  }),
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
};

Oops.defaultProps = {};

export default Oops;
