import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IncentiveItem from './IncentiveItem';

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  padding: 0;
  margin-left: 0.5rem;
`;

function Incentives({ incentives }) {
  if (!incentives) {
    return null;
  }

  return (
    <Ul>
      {incentives.map(el => (
        <IncentiveItem key={el.id} style={{ order: el.position }}>
          {el.name}
        </IncentiveItem>
      ))}
    </Ul>
  );
}

Incentives.propTypes = {
  incentives: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
    }),
  ),
};

Incentives.defaultProps = {};

export default Incentives;
