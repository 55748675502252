import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  transform: scale(1.2);
`;

function CheckboxIcon({ checked, ...rest }) {
  if (checked)
    return (
      <StyledSvg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" fill="black" stroke="black" />
        <path
          d="M4.36548 7.66667L6.94443 10.5L11.3655 6"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </StyledSvg>
    );

  return (
    <StyledSvg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="15" height="15" rx="1.5" stroke="black" />
    </StyledSvg>
  );
}

CheckboxIcon.propTypes = {
  checked: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

CheckboxIcon.defaultProps = {};

export default CheckboxIcon;
