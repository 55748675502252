import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';

function IconButton({ children, ...rest }) {
  return (
    <Button style={{ height: 40, padding: 0, width: 40 }} {...rest}>
      {children}
    </Button>
  );
}

IconButton.propTypes = {
  children: PropTypes.any.isRequired,
};

IconButton.defaultProps = {};

export default IconButton;
