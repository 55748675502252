import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import BackButton from '../Button/BackButton';
import { Description, Title } from '../TextStyles';
import DotLoader from '@web/components/DotLoader';
import Message from './Message';
import NorthDakotaVersion from './NorthDakotaVersion';
import Share from './Share';

import { largeBreakpoint } from '../breakpoints';
import { track } from '@web/services/analytics';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QRCodeShare from '@web/components/FanOut/components/QRCodeShare';
import {
  setIsCheckedVoterRegistration,
  setContactFormResult,
} from '@web/reducers/voterRegistration';
import Status from '@web/constants/contactRegistrationStatusTypes';
import { capitalize } from '@web/utils/string';
import { fanOut } from '@web/services/api';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';

export const StyledVotingInformation = styled.div``;

export const ButtonRow = styled.div`
  margin-top: 40px;
  ${largeBreakpoint} {
    display: none;
  }
`;

export const Line = styled.div`
  background-color: rgba(0, 0, 0, 0.07);
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
`;

const StyledBackButton = styled(BackButton)`
  text-decoration: ${({ $isFanOut }) => ($isFanOut ? 'underline' : 'none')};
`;

function VotingInformation({ activity, handleClose, team, isFanOut, referrer }) {
  const { contactFormResult, selectedVoter } = useSelector(state => state.voterRegistration);
  const { search } = useLocation();
  const { referral_hash: referralHash } = queryString.parse(search);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // In some cases we use "parsedVoter" which means there is no "voterfile" object in it.
  // In some cases we use "voter" from the API response which means there is "voterfile" object in it
  // We have to handle both cases to prevent bugs
  const registered = React.useMemo(() => {
    const voterStatus = selectedVoter?.voterfile
      ? selectedVoter?.voterfile?.['vb.voterbase_registration_status']
      : selectedVoter?.['vb.voterbase_registration_status'];

    return voterStatus === Status.REGISTERED;
  }, [selectedVoter]);
  const descriptionText = React.useMemo(
    () =>
      registered
        ? t('voting_information.descriptionRegistered')
        : isFanOut
        ? t('voting_information.descriptionNotRegisteredFanOut')
        : t('voting_information.descriptionNotRegistered'),
    [registered, isFanOut, t],
  );
  const isFanOutReferrer = React.useMemo(() => referrer && isFanOut, [referrer, isFanOut]);

  React.useEffect(() => {
    track('VR_VIEW_VOTING_INFORMATION', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
    });

    if (isFanOut) {
      amplitudeTrack({ activity, name: 'FAN_OUT_VIEW_VOTING_INFORMATION', team });
      dispatch(setIsCheckedVoterRegistration(true));
      dispatch(
        setContactFormResult({
          activity,
          contact: {
            ...contactFormResult,
            address: registered
              ? `${selectedVoter.addressLine1 || ''} ${selectedVoter.addressLine2 || ''}`
              : contactFormResult.address,
            registration_status: registered ? Status.REGISTERED : Status.UNREGISTERED,
          },
        }),
      );
      // count points for registration
      fanOut
        .incrementPoints({
          activity_id: activity?.id,
          campaign_id: activity?.campaign?.id,
          contact_id: contactFormResult?.id,
          email: contactFormResult.email,
          metric: 'CheckRegistration',
          phone: contactFormResult.phone,
          referral_hash: referralHash,
        })
        .then(({ data }) => {
          // send auth email only if prev request was successful
          fanOut
            .sendAuthEmail({
              activity_id: activity?.id,
              activity_slug: activity?.slug,
              campaign_id: activity?.campaign?.id,
              campaign_slug: activity?.campaign?.slug,
              email: contactFormResult.email,
              phone: contactFormResult.phone,
              referral_link: contactFormResult.referral_link,
              registration_status: registered ? Status.REGISTERED : Status.UNREGISTERED,
            })
            .catch(error => {
              message.error(
                error?.response?.data?.message ||
                  error?.message ||
                  t('common.something_went_wrong'),
              );
              console.error(error);
            });

          if (isFanOutReferrer) {
            if (data.is_points_earned) {
              message.success(
                t('voting_information.earned_entries', {
                  name: capitalize(referrer?.fullname || ''),
                }),
              );
            } else {
              message.error(
                t('voting_information.not_earned_entries', {
                  name: capitalize(referrer?.fullname || ''),
                }),
              );
            }
          }
        })
        .catch(error => {
          message.error(
            error?.response?.data?.message || error?.message || t('common.something_went_wrong'),
          );
          console.error(error);
        });
    }
    // eslint-disable-next-line
  }, []);

  if (!contactFormResult?.voting_info) {
    return <DotLoader centered color="black" />;
  }

  // If the voter is in North Dakota we display a custom version of this screen.
  if (contactFormResult?.state_abbrev?.toUpperCase() === 'ND') {
    return <NorthDakotaVersion activity={activity} handleClose={handleClose} team={team} />;
  }

  return (
    <StyledVotingInformation>
      <Title>{t('voting_information.title')}</Title>
      <Description>{descriptionText}</Description>
      <Message registered={registered} isFanOut={isFanOut} />
      {isFanOut ? (
        <QRCodeShare activity={activity} team={team} isFanOut />
      ) : (
        <Share activity={activity} team={team} />
      )}
      <ButtonRow>
        <StyledBackButton $isFanOut={isFanOut} onClick={handleClose} showIcon={false}>
          {t('common.close')}
        </StyledBackButton>
      </ButtonRow>
    </StyledVotingInformation>
  );
}

VotingInformation.propTypes = {
  activity: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  isFanOut: PropTypes.bool,
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  team: PropTypes.object,
};

VotingInformation.defaultProps = {};

export default VotingInformation;
