import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import ProgressBar from '@web/components/FanOutVotingPlan/components/ProgressBar';
import PageTitle from '@web/components/FanOutVotingPlan/components/PageTitle';
import {
  getInitialFormValues,
  getValidationFormSchema,
} from '@web/components/FanOutVotingPlan/utils';
import ButtonsSection from '@web/components/FanOutVotingPlan/components/ButtonsSection';
import InfoFields from './InfoFields';
import { capitalize } from '@web/utils/string';
import { fanOut } from '@web/services/api';
import { Wrapper, Content } from '../styles';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const BottomText = styled.div`
  color: ${({ theme }) => theme.colors.blackL42};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
`;

const StyledForm = styled(Form)`
  ${smallBreakpoint} {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const TellUs = ({
  progress,
  formAttributes,
  referrer,
  activity,
  referralHash,
  currentPage: { nextButtonText, nextIcon },
  onNextPage,
  setFormVPAnswers,
  formVPAnswers,
  onPreviousPage,
}) => {
  const { t } = useTranslation();
  const initialValues = getInitialFormValues(formAttributes, formVPAnswers);
  const validationSchema = getValidationFormSchema(formAttributes);

  const onSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      fanOut
        .validateVoterRegistration({
          campaign_id: activity.campaign.id,
          email: values.email,
          phone: `+${values.phone}`,
          referral_hash: referralHash,
        })
        .then(({ data }) => {
          setFormVPAnswers(values);
          onNextPage();
        })
        .catch(err => {
          if (err?.response?.data?.error_type === 'own_vr') {
            message.error("You can't submit your own Voting Plan.");
          } else {
            onPreviousPage(err?.response?.data?.error_type, err?.response?.data?.referral_name);
          }
        })
        .finally(() => setSubmitting(false));
    },
    [activity.campaign.id, referralHash, onNextPage, setFormVPAnswers, onPreviousPage],
  );

  return (
    <Wrapper>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Wrapper>
              <Content>
                <ProgressBar progress={progress} />
                <PageTitle icon={'👤'} title={t('fan_out.voting_plan.tell_us.tell')} />
                <InfoFields
                  initialValues={initialValues}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <BottomText>
                  {t('fan_out.voting_plan.tell_us.be_sure', {
                    referrer: capitalize(referrer?.fullname || ''),
                  })}
                </BottomText>
              </Content>
            </Wrapper>
            <ButtonsSection
              nextButtonProps={{
                disabled: isSubmitting,
                htmlType: 'submit',
                icon: nextIcon,
                text: nextButtonText,
              }}
            />
          </StyledForm>
        )}
      />
    </Wrapper>
  );
};

TellUs.propTypes = {
  activity: PropTypes.object.isRequired,
  currentPage: PropTypes.shape({
    nextButtonText: PropTypes.string,
    nextIcon: PropTypes.node,
    prevButtonText: PropTypes.string,
    prevIcon: PropTypes.node,
  }).isRequired,
  formAttributes: PropTypes.object.isRequired,
  formVPAnswers: PropTypes.object.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  progress: PropTypes.number,
  referralHash: PropTypes.string.isRequired,
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  setFormVPAnswers: PropTypes.func.isRequired,
  sortedActivityQuestions: PropTypes.array.isRequired,
};

TellUs.defaultProps = {
  progress: 0,
};

export default TellUs;
