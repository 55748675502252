import React, { useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { message, Tooltip } from 'antd';
import Clipboard from 'react-clipboard.js';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';
import Copy from '@web/components/FanOut/components/icons/Copy';
import DesktopRecruit, { flex } from './DesktopRecruit';
import MobileRecruit from './MobileRecruit';
import { fanOut } from '@web/services/api';
import { toObject, usePaginatedApi } from '@web/hooks/usePaginatedApi';
import Loading from '@web/components/common/Loading';
import { useGetVotingPlanReferralLink } from '@web/components/FanOut/hooks/useFanOut';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';
import ShareIcon from '@web/components/VoterRegistration/newComponents/icons/Share';
import theme from '@web/styles/theme';
import sendError from '@web/utils/sendError';
import Button from '@web/components/VoterRegistration/newComponents/Button';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  flex-direction: column;
  overflow: hidden;
`;

const BasicText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(BasicText)`
  flex: 10;
  margin: 24px 0 12px 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.5rem; /* 116.667% */

  ${smallBreakpoint} {
    font-weight: 600;
    line-height: 2.25rem; /* 112.5% */
  }
`;

const StyledButton = styled(Button)`
  &&& {
    margin-top: 12px;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.0625rem;

    flex: 1;
    gap: 6px;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 1.5rem;
    padding: 0;
    background: ${({ theme }) => theme.colors.transparent};
    border: none;

    &::after {
      all: unset;
    }
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 12px 20px ${({ $isEmpty }) => ($isEmpty ? '12px' : '0')} 20px;
  background: ${({ theme }) => theme.colors.white};
  gap: 5px;
`;

const BoxTitle = styled(BasicText)`
  font-weight: 600;
  text-transform: uppercase;
`;

const StyledClipboard = styled(Clipboard)`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  text-transform: uppercase;
  gap: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const LoadingBox = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const NoRecruitsText = styled(BasicText)`
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
`;

const Recruits = ({ activity, phone, team }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const {
    hasMore,
    items: recruits,
    loadMore,
    loading,
    initialLoading,
  } = toObject(
    usePaginatedApi(fanOut.getRecruits, null, {
      phone,
    }),
  );

  const { votingPlanReferralLink, votingPlanReferralLinkLoading } = useGetVotingPlanReferralLink({
    campaignId: activity.campaign.id,
    phone,
  });

  useEffect(() => {
    if (initialLoading && !loading) {
      loadMore();
    }
  }, [initialLoading, loadMore, loading]);

  const showMobileShare = useMemo(() => navigator?.share, []);

  const mobileShare = useCallback(() => {
    const shareData = {
      text: t('check_registration.referral.fanOutVotingPLanMobileShareText'),
      title: activity.title,
      url: votingPlanReferralLink,
    };
    if (navigator?.canShare && navigator.canShare(shareData) && navigator.share) {
      navigator.share(shareData).catch(err => {
        if (err.toString().includes('AbortError')) {
          // The share api throws this error when a user decides not to share.
          // It isn't a real error.
          return null;
        }
        sendError('FanOut Voter Registration: User encountered error while sharing', { err });
      });
    }
  }, [activity.title, votingPlanReferralLink, t]);

  const copyVotingReferralLink = useCallback(() => {
    amplitudeTrack({ activity, name: 'FAN_OUT_COPY_VOTING_PLAN_LINK', team });
    message.success(t('activity.share.copied_to_clipboard'));
  }, [t, activity, team]);

  const RecruitComponent = isMobile ? MobileRecruit : DesktopRecruit;

  return (
    <>
      <TitleWrapper>
        <Title>{t('fan_out.my_activity.recruits_title')}</Title>
        {isMobile &&
          (showMobileShare ? (
            <StyledButton onClick={mobileShare} variant="fan_out">
              {t('fan_out.my_activity.share_voting_plan')}
              <ShareIcon fill={theme.colors.blue} />
            </StyledButton>
          ) : (
            <Clipboard
              component="span"
              data-clipboard-text={votingPlanReferralLink}
              onSuccess={copyVotingReferralLink}
            >
              <StyledButton onClick={null} variant="fan_out">
                {t('fan_out.my_activity.copy_voting_plan')}
                <Copy fill={theme.colors.blue} />
              </StyledButton>
            </Clipboard>
          ))}
      </TitleWrapper>
      <Wrapper>
        {!isMobile && (
          <TopContainer $isEmpty={recruits.length === 0}>
            <BoxTitle style={flex[0]}>{t('fan_out.my_activity.recruit')}</BoxTitle>
            <BoxTitle style={flex[1]}>{t('fan_out.my_activity.voting_status')}</BoxTitle>
            <StyledClipboard
              component="span"
              data-clipboard-text={votingPlanReferralLink}
              onSuccess={copyVotingReferralLink}
              style={flex[2]}
            >
              {t('fan_out.my_activity.voting_plan')}
              <Tooltip title={t('fan_out.my_activity.voting_plan_tooltip')}>
                <Copy />
              </Tooltip>
            </StyledClipboard>
            <BoxTitle style={flex[3]}>
              {t('fan_out.my_activity.entries_earned', { count: 0 })}
            </BoxTitle>
            <BoxTitle style={flex[4]}>{t('fan_out.my_activity.sweepstakes')}</BoxTitle>
          </TopContainer>
        )}
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          threshold={0}
          loadMore={loadMore}
          hasMore={!loading && hasMore}
        >
          {recruits.map(item => (
            <RecruitComponent key={item.id} recruit={item} />
          ))}
          {(loading || votingPlanReferralLinkLoading) && (
            <LoadingBox>
              <Loading />
            </LoadingBox>
          )}
          {recruits.length === 0 && !loading && !votingPlanReferralLinkLoading && (
            <LoadingBox>
              <NoRecruitsText>{t('fan_out.my_activity.no_recruits')}</NoRecruitsText>
            </LoadingBox>
          )}
        </InfiniteScroll>
      </Wrapper>
    </>
  );
};

Recruits.propTypes = {
  activity: PropTypes.object.isRequired,
  phone: PropTypes.string.isRequired,
  team: PropTypes.object,
};

Recruits.defaultProps = {};

export default Recruits;
