import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { message } from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import AddressInput from '@web/components/VoterRegistration/newComponents/Input/AddressInput';
import Button from '@web/components/VoterRegistration/newComponents/Button';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import Input from '@web/components/VoterRegistration/newComponents/Input';
import OptIns from './OptIns';
import SearchIcon from '../icons/Search';
import RefreshFormButton from '@web/components/common/RefreshFormIcon';

import { setContactFormResult } from '@web/reducers/voterRegistration';
import { track, trackEventViewWithDynamicIDs } from '@web/services/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useVoterRegistrationApi from '@web/components/VoterRegistration/hooks/useVoterRegistrationApi';
import Status from '@web/constants/contactRegistrationStatusTypes';

import { largeBreakpoint } from '../breakpoints';
import sendError from '@admin/utils/sendError';
import { capitalize } from '@web/utils/string';
import PhoneNumberInput from '@web/components/common/shared/PhoneNumberInput';
import { PhoneFilled } from '@ant-design/icons';
import { fanOut } from '@web/services/api';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const FormWrapper = styled(Form)`
  &&& {
    margin-top: 2rem;
  }
`;

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  ${largeBreakpoint} {
    grid-template-columns: 1fr 1fr;
  }
`;

const NameWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  ${largeBreakpoint} {
    grid-template-columns: 1fr 1fr;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 60px;

  ${largeBreakpoint} {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 58px;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 10px;
  path {
    fill: ${({ $isDisabled, $isFanOut, theme }) =>
      $isDisabled ? theme.colors.blackL72 : $isFanOut ? theme.colors.blue : theme.colors.white};
  }
`;

const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  gap: 1rem;
  :last-of-type {
    justify-content: flex-end;
  }
  ${largeBreakpoint} {
    flex-direction: row;
    justify-content: flex-start;
    button {
      max-width: 250px;
    }
  }
`;

function ContactForm({
  activity,
  backButton,
  initialValues,
  nextPage,
  schema,
  secondaryButton,
  submitButtonChildren,
  team,
  type,
  isFanOut,
  referrer,
}) {
  const addressRef = React.useRef(null);
  const { search } = useLocation();
  const { start } = queryString.parse(search);
  const dispatch = useDispatch();
  const contact = useSelector(state => state.voterRegistration.contactFormResult);
  const { t } = useTranslation();
  const { handleError, submitContactForm, submitContactUpdate } = useVoterRegistrationApi(
    activity,
    team,
  );
  const showOptIns = type !== 'update';

  function handlePixelTracking() {
    trackEventViewWithDynamicIDs({
      actionName: isFanOut ? 'fan_out_form_filled' : 'vr_form_filled',
      categoryName: isFanOut ? 'FanOut Form Filled' : 'Voter Registration Form Filled',
      fbPixelId: activity?.settings?.pixel_fb_id,
      gaTrackingId: activity?.settings?.pixel_ga_id,
      gtmPixelId: activity?.settings?.pixel_gtm_id,
      snapchatPixelId: activity?.settings?.pixel_sc_id,
    });
  }

  function handleSubmit(...args) {
    // if we are in the fan out flow, we want to check if combination of phone & email is valid
    if (isFanOut) {
      fanOut
        .validatePhoneAndEmail({
          campaign_id: activity.campaign.id,
          email: args[0].email,
          phone: args[0].phone.startsWith('+') ? args[0].phone : `+${args[0].phone}`,
        })
        .then(({ data }) => {
          // if the phone and email are valid, we can submit the form
          if (type === 'create') {
            track('SUBMIT_VOTER_REGISTRATION_FORM');
            amplitudeTrack({ activity, name: 'FAN_OUT_SUBMIT_VOTER_REGISTRATION_FORM', team });

            return createContact(...args);
          } else {
            track('SUBMIT_VOTER_REGISTRATION_FORM_UPDATE');
            amplitudeTrack({
              activity,
              name: 'FAN_OUT_SUBMIT_VOTER_REGISTRATION_FORM_UPDATE',
              team,
            });
            handlePixelTracking();
            return submitUpdate(...args);
          }
        })
        .catch(err => {
          handleError(err);
          args[1].setSubmitting(false);
        });
    }
    // if we are not in the fan out flow, we can just submit the form
    else {
      if (type === 'create') {
        track('SUBMIT_VOTER_REGISTRATION_FORM');
        return createContact(...args);
      } else {
        track('SUBMIT_VOTER_REGISTRATION_FORM_UPDATE');
        return submitUpdate(...args);
      }
    }
  }

  function createContact(values, { setSubmitting }) {
    setSubmitting(true);
    // TODO: This will be uncommented when we build the other implementations of this
    // component.
    // values.registrationStatus = registrationStatus;

    values.auto_open_link = start === '1';
    submitContactForm(values)
      .then(({ data }) => {
        // MTS - We add phone and dob here because the backend doesn't send this back for some reason.
        // RH - looks like we already have this in the response
        dispatch(
          setContactFormResult({
            activity,
            contact: {
              ...data,
              // convert into the right format
              date_of_birth: dayjs(data.date_of_birth).format('MM/DD/YYYY'),
              registration_status: isFanOut ? Status.UNCONFIRMED : data.registration_status,
            },
          }),
        );
        handlePixelTracking();
        nextPage();
      })
      .catch(err => {
        const errorMessage = handleError(err);
        sendError(`VR Flow Contact form: ${errorMessage}`, err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function submitUpdate(values, { setSubmitting }) {
    submitContactUpdate(contact, values)
      .then(({ data }) => {
        // MTS - We add phone and dob here because the backend doesn't send this back for some reason.
        // RH - looks like we already have this in the response
        dispatch(
          setContactFormResult({
            activity,
            contact: {
              ...data,
              // convert into the right format
              date_of_birth: dayjs(data.date_of_birth).format('MM/DD/YYYY'),
              registration_status: isFanOut ? Status.UNCONFIRMED : data.registration_status,
            },
          }),
        );
        handlePixelTracking();
        nextPage();
      })
      .catch(err => {
        const errorMessage = handleError(err);
        sendError(`VR Flow Contact form: ${errorMessage}`, err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function handleAddressChange(address, setFieldValue) {
    setFieldValue('address', address.address);
    setFieldValue('city', address.city);
    setFieldValue('zipCode', address.zip_code);
    setFieldValue('countyName', address.county_name);
    setFieldValue('stateAbbrev', address.state_abbrev);
  }

  const handleDateChange = ({ target: { value } }, setFieldValue) => {
    setFieldValue('date_of_birth', value);
  };

  const isDisabled = React.useCallback((errors, touched, values) => {
    const isTouched = !!Object.keys(touched).length;
    const hasErrors = !!Object.keys(errors).length;
    const { address, date_of_birth, email, firstName, lastName, phone } = values;
    const isAllValues =
      !!address && !!date_of_birth && !!email && !!firstName && !!lastName && !!phone;

    // RH - if all values are filled from initial values, then isTouched will be false, but we still want to enable the button
    return !!(hasErrors || (isAllValues && !isTouched) ? !isAllValues : !isTouched);
  }, []);

  const isFanOutReferrer = React.useMemo(() => referrer && isFanOut, [referrer, isFanOut]);

  const onSubmit = React.useCallback(
    errors => {
      if (isFanOut) {
        if (errors?.date_of_birth === t('idvoters.validation.max_date', { age: 18 })) {
          const referral = isFanOutReferrer
            ? t('idvoters.validation.referrer_entries', {
                referrer: capitalize(referrer?.fullname || ''),
              })
            : '';

          message.error(t('idvoters.validation.election_date', { referral, year: 2024 }));
        }
      }
    },
    [isFanOut, isFanOutReferrer, referrer, t],
  );

  const refreshForm = React.useCallback(setFieldValue => {
    const fieldsToClear = [
      'firstName',
      'lastName',
      'searchAddress',
      'address2',
      'date_of_birth',
      'phone',
      'email',
      'address',
      'city',
      'zipCode',
      'countyName',
      'stateAbbrev',
    ];

    fieldsToClear.forEach(f => setFieldValue(f, ''));
    if (addressRef.current) {
      addressRef.current.value = '';
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      render={({ errors, isSubmitting, setFieldValue, touched, values }) => (
        <FormWrapper>
          <Grid>
            <RefreshFormButton
              position={{ right: 0, top: '-30px' }}
              clearData={() => refreshForm(setFieldValue)}
            />
            <Input
              autoComplete="given-name"
              icon="person"
              label={t('idvoters.labels.firstname')}
              name="firstName"
              placeholder={t('idvoters.labels.firstname')}
              required
            />
            <Input
              autoComplete="family-name"
              icon="person"
              label={t('idvoters.labels.lastname')}
              name="lastName"
              placeholder={t('idvoters.labels.lastname')}
              required
            />
            <AddressInput
              autoComplete="off"
              defaultValue={initialValues?.address || ''}
              icon="location"
              label={t('idvoters.labels.address1')}
              name="searchAddress"
              onChange={val => handleAddressChange(val, setFieldValue)}
              placeholder={t('idvoters.placeholders.address1')}
              inputRef={addressRef}
              required
            />
            <Input
              autoComplete="off"
              icon="house"
              label={t('idvoters.labels.address2')}
              name="address2"
              placeholder={t('idvoters.placeholders.address2')}
            />
            <NameWrapper>
              <Input
                autoComplete="bday"
                icon="calendar"
                label={t('idvoters.labels.date_of_birth')}
                mask="99/99/9999"
                name="date_of_birth"
                onChange={event => handleDateChange(event, setFieldValue)}
                placeholder="MM/DD/YYYY"
                type="tel"
                required
              />
              <PhoneNumberInput
                name="phone"
                label={t('idvoters.labels.phone')}
                placeholder="(555) 345-6789"
                onChange={e => setFieldValue('phone', e)}
                value={values.phone}
                prefixIcon={<PhoneFilled />}
                shouldFocusIcon
                isRequired
                labelStyles={{ color: 'black', opacity: '0.85', textTransform: 'capitalize' }}
              />
            </NameWrapper>
            <Input
              autoComplete="email"
              icon="email"
              label={t('idvoters.labels.email')}
              name="email"
              placeholder={t('idvoters.labels.emailPlaceholder')}
              type="email"
              required
            />
          </Grid>
          {showOptIns && (
            <OptIns
              activity={activity}
              setFieldValue={setFieldValue}
              team={team}
              values={values}
              isFanOut={isFanOut}
            />
          )}
          <ButtonRow>
            <ButtonGroup>{backButton || secondaryButton}</ButtonGroup>
            <ButtonGroup>
              {backButton && secondaryButton}

              <Button
                htmlType="submit"
                loading={isSubmitting}
                isFanOut={isFanOut}
                variant={
                  isDisabled(errors, touched, values) ? 'disabled' : isFanOut ? 'fan_out' : 'blue'
                }
                onClick={() => onSubmit(errors)}
              >
                <div key="buttonContent">
                  {submitButtonChildren && submitButtonChildren}
                  {!submitButtonChildren && (
                    <>
                      <StyledSearchIcon
                        $isDisabled={isDisabled(errors, touched, values)}
                        $isFanOut={isFanOut}
                      />
                      {t('idvoters.search')}
                    </>
                  )}
                </div>
              </Button>
            </ButtonGroup>
          </ButtonRow>
        </FormWrapper>
      )}
    />
  );
}

ContactForm.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number,
    }),
    settings: PropTypes.shape({
      pixel_fb_id: PropTypes.string,
      pixel_ga_id: PropTypes.string,
      pixel_gtm_id: PropTypes.string,
      pixel_sc_id: PropTypes.string,
    }),
  }).isRequired,
  backButton: PropTypes.any,
  initialValues: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  schema: PropTypes.object.isRequired,
  secondaryButton: PropTypes.any,
  submitButtonChildren: PropTypes.any,
  team: PropTypes.shape({}),
  type: PropTypes.oneOf(['create', 'update']),
};

ContactForm.defaultProps = {
  backButton: null,
  extraButton: null,
  type: 'create',
};

export default ContactForm;
