import styled from 'styled-components';
import { largeBreakpoint } from '../breakpoints';

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0.5rem;
  ${largeBreakpoint} {
    font-size: 30px;
  }

  span {
    text-decoration: underline;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  ${largeBreakpoint} {
    font-size: 16px;
  }
`;
