import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledReason = styled.li`
  display: flex;
  margin-top: 1rem;
`;

const IconArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5px;
  max-width: 36px;
  min-width: 36px;
`;

const Text = styled.p`
  font-size: 1rem;
  margin-bottom: 0;
`;

function Reason({ icon, text }) {
  return (
    <StyledReason>
      <IconArea>{icon}</IconArea>
      <Text>{text}</Text>
    </StyledReason>
  );
}

Reason.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.any.isRequired,
};

Reason.defaultProps = {};

export default Reason;
