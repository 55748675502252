import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import TimelineItem from './TimelineItem';
import getUnregisteredTimelines from './newNotRegisteredTimelines';
import getRegisteredTimelines from './newRegisteredTimelines';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sortTimelines } from './utils';

dayjs.extend(customParseFormat);

const StyledTimeline = styled.ul`
  border-radius: 8px;
  padding: 11px 8px;
  margin: 0;
`;

function Timeline({ registered, votingInfo, contact }) {
  const { t } = useTranslation();
  const { contactFormResult, selectedVoter } = useSelector(state => state.voterRegistration);

  function getState() {
    if (selectedVoter && Object.keys(selectedVoter).length !== 0) {
      return selectedVoter?.state?.toUpperCase();
    }
    return contact?.state_abbrev?.toUpperCase() || contactFormResult?.state_abbrev?.toUpperCase();
  }

  const state = getState();

  const timeline = registered
    ? getRegisteredTimelines(votingInfo, t, state)
    : getUnregisteredTimelines(votingInfo, t, state);

  const sortedTimeline = sortTimelines(timeline);

  return (
    <StyledTimeline>
      {sortedTimeline.map((el, i) => (
        <TimelineItem
          first={i === 0}
          key={i}
          last={i === timeline.length - 1}
          registered={registered}
          {...el}
        />
      ))}
    </StyledTimeline>
  );
}

Timeline.propTypes = {
  contact: PropTypes.object,
  registered: PropTypes.bool.isRequired,
  votingInfo: PropTypes.object.isRequired,
};

Timeline.defaultProps = {};

export default Timeline;
