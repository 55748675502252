import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import CreateVotingPlan from '@web/components/FanOutVotingPlan/components/CreateVotingPlan';
import FanOutLogo from '@web/components/FanOut/components/icons/FanOutLogo';
import {
  small800Breakpoint,
  smallBreakpoint,
} from '@web/components/VoterRegistration/newComponents/breakpoints';
import theme from '@web/styles/theme';
import {
  useVotingPlan,
  useSurveyActivity,
} from '@web/components/FanOutVotingPlan/hooks/useVotingPlan';
import { useGetReferrer } from '@web/components/FanOut/hooks/useFanOut';
import { useTrackFanOut } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const Wrapper = styled.div`
  display: flex;
  background: #f8f9fd;
  min-height: 100vh;
  padding: 32px;
  gap: 45px;
  justify-content: space-between;

  ${({ $lastPage }) =>
    $lastPage &&
    css`
      justify-content: center;
      background: radial-gradient(
          64.5% 64.52% at 97.64% 1.3%,
          rgba(197, 55, 94, 0.2) 0%,
          rgba(197, 55, 94, 0) 100%
        ),
        radial-gradient(
          66.22% 90.3% at 98.11% 98.85%,
          rgba(140, 205, 217, 0.2) 0%,
          rgba(140, 205, 217, 0) 100%
        ),
        radial-gradient(
          64.49% 126.95% at 0% 100%,
          rgba(184, 59, 201, 0.2) 0%,
          rgba(184, 59, 201, 0) 100%
        ),
        radial-gradient(
          86.75% 134.97% at 0% 0%,
          rgba(152, 204, 201, 0.2) 0%,
          rgba(152, 204, 201, 0) 100%
        ),
        radial-gradient(
          53.76% 53.76% at 50% 46.24%,
          rgba(225, 159, 83, 0.2) 0%,
          rgba(225, 159, 83, 0) 100%
        ),
        #fff;
    `}

  ${smallBreakpoint} {
    padding: 20px;
    gap: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }
`;

const StyledFanOutLogo = styled(FanOutLogo)`
  position: absolute;
  left: 40px;
  top: 40px;
  width: 107px;
  height: 20px;

  ${small800Breakpoint} {
    top: 20px;
    left: 20px;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 64px);

  ${smallBreakpoint} {
    min-height: unset;
  }
`;

const VotingPlan = ({ activity }) => {
  const { activitySlug, campaignSlug } = useParams();
  const { search } = useLocation();
  const { referral_hash: referralHash } = queryString.parse(search);
  const votingPlanProps = useVotingPlan();

  const surveyProps = useSurveyActivity({ activity });
  const { referrer, referrerLoading } = useGetReferrer({
    activitySlug,
    campaignSlug,
    referralHash,
  });

  // Voting plan pages
  const {
    currentPage: { Component, index, pageName },
  } = votingPlanProps;

  useTrackFanOut({ activity, name: `FAN_OUT_VOTING_PLAN_${pageName}` });

  if (referrerLoading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }
  return (
    <Wrapper $lastPage={index === 4}>
      {index === 4 ? (
        <>
          <StyledFanOutLogo fill={theme.colors.trueBlack} />
          <Component
            {...votingPlanProps}
            {...surveyProps}
            referrer={referrer}
            referralHash={referralHash}
            activity={activity}
          />
        </>
      ) : (
        <>
          <CreateVotingPlan referrer={referrer} />
          <RightWrapper>
            <Component
              {...votingPlanProps}
              {...surveyProps}
              referrer={referrer}
              referralHash={referralHash}
              activity={activity}
            />
          </RightWrapper>
        </>
      )}
    </Wrapper>
  );
};

VotingPlan.propTypes = {
  activity: PropTypes.object.isRequired,
};

VotingPlan.defaultProps = {};

export default VotingPlan;
