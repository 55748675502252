import * as React from 'react';
import PropTypes from 'prop-types';

import BackButton from '@web/components/VoterRegistration/newComponents/Button/BackButton';
import ContactForm from '@web/components/VoterRegistration/newComponents/ContactForm';
import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';

import { track } from '@web/services/analytics';
import { fanOutRegistration } from '@web/components/VoterRegistration/hooks/useFlows';
import useContactForm from '@web/components/VoterRegistration/hooks/useContactForm';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function TellUs(props) {
  const { t } = useTranslation();
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { activity, team, isFanOut } = props;
  const { initialValues, schemas } = useContactForm(contactFormResult, activity);
  const onBackButtonClick = () => {
    if (props.isFanOut) {
      props.setSelectedFlow(fanOutRegistration);
    } else {
      props.previousPage();
    }
  };
  const backButton = (
    <BackButton onClick={onBackButtonClick} isFanOut={props.isFanOut}>
      {t('common.back')}
    </BackButton>
  );

  React.useEffect(() => {
    track('VR_VIEW_REGISTER_FORM', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Title>{t('check_registration.tellUs.title')}</Title>
      <Description>{t('check_registration.tellUs.description')}</Description>
      <ContactForm
        backButton={backButton}
        initialValues={initialValues.contactForm}
        submitButtonChildren={t('common.submit')}
        schema={isFanOut ? schemas.fanOutContactForm : schemas.contactForm}
        {...props}
      />
    </>
  );
}

TellUs.propTypes = {
  activity: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  previousPage: PropTypes.func.isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

TellUs.defaultProps = {
  team: null,
};

export default TellUs;
